import React, { useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import styles from './pdfViewer.module.css'; // Import CSS for styling
import fullscreenImage from '../img/fullscreen.svg';


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

let pdfDoc = null,
    canvas = null,
    ctx = null,
    scale = 1,
    minScale = 0.8,
    maxScale = 3,
    pageNum = 1,
    pageRendering = false,
    pageNumPending = null,
    // totalPages = null,
    // currentPage = null,
    isFullscreen = false
const PDFViewer = ({ pdfUrl,isPdf }) => {
//   const [pdfDoc, setPdfDoc] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
//   const [pageNum, setPageNum] = useState(1);
//   const [scale, setScale] = useState(1);
// const [isFullscreen, setIsFullscreen] = useState(false);
  const renderTaskRef = useRef(null); // Ref to hold the render task
  const [currentPage, setCurrentPage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  console.log("pdfUrl---",pdfUrl,isPdf);
  useEffect(() => {
    console.log("i am called----1");
    if(isPdf)
    {
      console.log("i am called----2");
    const fetchPdf = async () => {
        try {
          console.log("i am called----3");
          const url = pdfUrl.filepath; // Use the PDF URL directly
          const pdfDocument = await pdfjs.getDocument(url).promise;
          console.log("pdf document------------",pdfDocument);
        //   setPdfDoc(pdfDocument);
          pdfDoc=pdfDocument;
          setTotalPages(pdfDocument.numPages);
          renderPage(pageNum); // Render the initial page
          setLoading(false);
        } catch (error) {
          console.error("Error loading PDF:", error);
        }
      };
    fetchPdf();

    // Cleanup function to cancel the render task when component unmounts
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
    }
  }, [pdfUrl, pageNum,isPdf]); // Include pageNum in dependency array to re-render on page change

  const renderPage = (num) => {
    pageRendering = true;
    pdfDoc.getPage(num).then(function(page) {
      canvas = document.getElementById('the-canvas');
      ctx = canvas.getContext('2d');
      let viewport = page.getViewport({scale: scale});
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      let renderContext = {
        canvasContext: ctx,
        viewport: viewport
      };
      let renderTask = page.render(renderContext);
      renderTask.promise.then(function() {
        pageRendering = false;
        if (pageNumPending!== null) {
          renderPage(pageNumPending);
          pageNumPending = null;
        }
        setCurrentPage(num);
        // setProgress(0);
      }, function(reason) {
        console.error(reason);
        pageRendering = false;
      });
    });
  }
  

  const queueRenderPage = (num) =>{
      if (pageRendering) {
          pageNumPending = num;
        } else {
          renderPage(num);
          const progressValue = (num / totalPages) * 100
          setProgress(progressValue); 
      }
  }

  const onPrevPage = () =>{
      if (pageNum <= 1) {
          return;
        }
        pageNum--;
        queueRenderPage(pageNum);
  }

  const onNextPage = () =>{
      if (pageNum >= pdfDoc.numPages) {
          return;
      }
      pageNum++;
      queueRenderPage(pageNum);
  }

  const zoomIn = () => {
      if (scale < maxScale) {
          scale = scale + 0.1;
          renderPage(pageNum);
      }
    };
    
  const zoomOut = () => {
      if (scale > minScale) {
          scale = scale - 0.1;
        renderPage(pageNum);
      }
  };

  const toggleFullscreen = () => {
    const container = document.querySelector(`.${styles.main}`);
    const pdfCanvas = document.querySelector(`.${styles.pdf}`);
    if (!document.fullscreenElement) {
        scale = 2
        renderPage(pageNum);
        if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen();
        }
        isFullscreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          isFullscreen = false;
          scale = 1
          renderPage(pageNum);
        }
      }
  };

  console.log("totalpages",totalPages);

  return (
    <>
  <div className={styles.main}>
  {isPdf ? (
        loading ? (
          <div style={{fontSize:20,fontWeight:500}}>Loading Document.....</div>
        ) : (
          <>
            <div
              className="progress np-progress w-100"
              style={{ height: 9, position: 'sticky', top: 0 }}
            >
              <div
                className="progress-bar np-progress-bar-primary"
                role="progressbar"
                style={{ width: progress + '%' }}
                aria-valuemin={0}
                aria-valuemax={100}
              />
            </div>
            <div className={styles.pdf}>
              <canvas id="the-canvas"></canvas>
            </div>
            <div className={styles.controls}>
              <div className={styles.zoom}>
                <button id="zoom_out" onClick={zoomOut}>-</button>
                <button id="zoom_in" onClick={zoomIn}>+</button>
              </div>
              <div className={styles.pagination}>
                <button id="prev" onClick={onPrevPage}>&#11013;</button>
                <span className='mx-3'>
                  <span id="page_num">{currentPage}</span> of <span id="page_count">{totalPages}</span>
                </span>
                <button id="next" onClick={onNextPage}>&#11157;</button>
              </div>
              <div className={styles.fullScreen} onClick={toggleFullscreen}>
                <img src={fullscreenImage} width={14} height={15} alt='#' />
              </div>
            </div>
          </>
        )
      ) : (
        <img src={pdfUrl.file} alt="Image" />
      )}

  </div>
  <div>
    <span style={{fontSize:20,fontWeight:500}}>{pdfUrl.name}</span>
  </div>
</>

  );
};

export default PDFViewer;
