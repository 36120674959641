import React from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import PasswordField from "@/shared/components/form/Password";
import FormField from '@/shared/components/form/FormField';
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { marginLeft } from "@/utils/directions";
import {
  AccountButton,
  AccountButtons,
  ForgotFormGroup,
} from "../AccountElements";
import validate from './validate';

const NewRegisterForm = ({ onSubmit, errorMessage }) => (
  <Form onSubmit={onSubmit} validate={validate}>
    {({ handleSubmit }) => (
      <FormContainer onSubmit={handleSubmit}>
        <Alert variant="danger" show={!!errorMessage}>
          {errorMessage}
        </Alert>
        <FormGroup>
          <FormGroupLabel>Name</FormGroupLabel>
          <FormGroupField>
            <FormGroupIcon>
              <AccountOutlineIcon />
            </FormGroupIcon>
            <Field
              name="name"
              component={FormField}
              type="text"
              placeholder="Name"
              className="input-without-border-radius"
            />
          </FormGroupField>
        </FormGroup>
        <FormGroup>
          <FormGroupLabel>Email</FormGroupLabel>
          <FormGroupField>
            <FormGroupIcon>
              <AlternateEmailIcon />
            </FormGroupIcon>
            <Field
              name="email"
              component={FormField}
              type="email"
              placeholder="example@mail.com"
              required
              className="input-without-border-radius"
            />
          </FormGroupField>
        </FormGroup>
        <ForgotFormGroup>
          <FormGroupLabel>Password</FormGroupLabel>
          <FormGroupField>
            <Field
              name="password"
              component={PasswordField}
              placeholder="Password"
              className="input-without-border-radius"
              required
              keyIcon
            />
          </FormGroupField>
        </ForgotFormGroup>
        <RegisterButtons>
          <AccountButton type="submit" variant="primary">
            Sign Up
          </AccountButton>
        </RegisterButtons>
      </FormContainer>
    )}
  </Form>
);

NewRegisterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

NewRegisterForm.defaultProps = {
  errorMessage: "",
};

export default NewRegisterForm;

// region STYLES

const RegisterButtons = styled(AccountButtons)`
  ${marginLeft}: 0!important;
  margin-bottom: 20px;

  button {
    margin-bottom: 0;
  }
`;

// endregion
