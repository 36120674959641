import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import storageService from "../../../../service/storage.service";
import { colorBorder, colorBackground, colorHover } from "@/utils/palette";
import { left } from "@/utils/directions";
import SidebarLink, { SidebarNavLink, SidebarLinkTitle } from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

const SidebarContent = ({ onClick, changeToLight, changeToDark, collapse }) => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    const user = storageService.getUser();
    if (user) {
      setRole(user.role);
    }
  }, []);

  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse}>
        <SidebarLink
          title="Documents"
          route="/documents/list"
          icon="home"
          onClick={onClick}
        />
      </SidebarBlock>
      {role === 'Super_Admin' &&
      <SidebarBlock collapse={collapse}>
        <SidebarLink
          title="Entity"
          route="/entity/list"
          icon="database"
          onClick={onClick}
        />
      </SidebarBlock>
      }
      {(role === 'Super_Admin' || role ==='Manager') &&(
        <SidebarBlock collapse={collapse}>
          <SidebarLink
            title="Users"
            route="/users/list"
            icon="users"
            onClick={onClick}
          />
        </SidebarBlock>
      )}
      {/* <SidebarBlock collapse={collapse}>
        <SidebarLink
          title="Library"
          icon="book"
          route="/library"
          onClick={onClick}
        />
      </SidebarBlock> */}
    </SidebarContentWrap>
  );
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  collapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  collapse: false,
  onClick: () => {},
};

export default SidebarContent;

// Styles and constants
const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    padding-top: 15px;

    ${(props) =>
      props.collapse &&
      `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`;

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid ${colorBorder};
  list-style-type: none;

  &:last-child {
    border: none;
  }

  @media screen and (min-width: 576px) {
    ${(props) =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: ${colorBackground(props)};
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          ${left(props)}: 70px;
        }
  
        &:hover {
          background: ${colorHover(props)};
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`;
