import axiosInstance from './axiosInstance.service';

const authService = {
  signup: async (data) => {
    try {
      const res = await axiosInstance.post('/user/register/', data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  signin: async (data) => {
    try {
      const res = await axiosInstance.post('/user/login/', data);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default authService;
