import React, { useEffect, useState } from "react";
import "./sharemodel.css"; // Import CSS file for modal styling
import userServices from "../../../../service/user.service";
import toast from "react-hot-toast";

const ShareModal = ({ isOpen, onClose, Users, doc_id, isPublic, public_role }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchSelected, setSearchSelected] = useState([]);
  const [selectedRole, setSelectedRole] = useState('Viewer');
  const [publicRole, setPublicRole] = useState(public_role);
  const [isDocumentPublic, setIsDocumentPublic] = useState(isPublic);
  const [usersLoaded,setUsersLoaded]=useState(false);
  const [userRole, setUserRole] = useState('');


  // Event handler to update the selected role
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleRoleChangePublic = (e) => {
    setPublicRole(e.target.value);
  };

  const handleRoleChangeUser = async (e, data) => {
    const newRole = e.target.value;
    let id = data.user.id
    setUserRole(newRole);
    toast.success("Role of user updated");

    const updatedUsers = selectedUsers.map((selectedUser) => {
      if (selectedUser.user.id === id) {
        return { ...selectedUser, role: newRole };
      }
      return selectedUser;
    });
    setSelectedUsers(updatedUsers);
    await userServices.editUserAccess(doc_id, id, { role: newRole });

  };

  const handleCheckboxChange = (e) => {
    setIsDocumentPublic(e.target.checked);
  };


  useEffect(() => {
    setFilteredUsers([]);
    setSelectedUsers(Users);
    setUsersLoaded(true);
    setIsDocumentPublic(isPublic);
    setSearchSelected([])
    setPublicRole(public_role);
  }, [Users, public_role]);


  const handleGenerateLink = () => {
    const baseUrl = process.env.REACT_APP_URL;  
    const publicLink = `${baseUrl}/document/view-rights/${doc_id}`;

    console.log('Generating public link:', publicLink);
    if (navigator.clipboard) {
      navigator.clipboard.writeText(publicLink)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((err) => {
          console.error('Error copying text: ', err);
        });
    } else {
      // Fallback for browsers that don't support clipboard API
      const el = document.createElement('textarea');
      el.value = publicLink;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      console.log('Text copied to clipboard (fallback)');
    }
    toast.success("Link coppied", publicLink)
  };


  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if(!query) {
      setFilteredUsers([]);
    } else {
      const response = await userServices.getUsers({ search: query });
      if (response) {
        setFilteredUsers(response.data.users);
      }
    }
    
  };

  const addUser = async (user) => {
    user.role = selectedRole;
    let record = { record: [user], document: isDocumentPublic, role: publicRole };
    const res = await userServices.addUserDocumentRole(doc_id, record);
    if (res) {
      setSearchQuery("");
      setFilteredUsers([]);
    }

  };

  const addUser1 = async () => {
    console.log("public role------", publicRole);
    let record = { record: searchSelected, document: isDocumentPublic, role: publicRole };
    const res = await userServices.addUserDocumentRole(doc_id, record);
    if (res) {
      toast.success("Rights Added");
      setSearchQuery("");
      setFilteredUsers([]);
    }

  };
  const handleUserSelect = (user) => {
    user.role = selectedRole;
    setSelectedUsers([...selectedUsers, user]);
    console.log("selected----", selectedUsers);
    addUser(user);
    setSearchQuery("");
    setFilteredUsers([]);
  };

  const removeUserAccess = async (id) => {
    const updatedSelectedUser = selectedUsers.filter((user) => user.user.id !== id);
    console.log(updatedSelectedUser)
    setSelectedUsers(updatedSelectedUser);
    const updatedSearchSelected = searchSelected.filter((user) => user.user.id !== id);
    setSearchSelected(updatedSearchSelected);
    toast.success("user deleted SuccessFully");
    // await userServices.removeUserAccess(doc_id, id);
  };

  const closePopup=()=>{
    setSelectedUsers([]);
    setUsersLoaded(false);
    onClose(isDocumentPublic, publicRole)
  }

  return (
    isOpen && (
      <div className="share-modal-overlay">
        <div className="share-modal-content">
          <div className="share-modal-header">
            <h3>Selected Users and Roles</h3>
            <button className="close-btn" onClick={closePopup}>
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M8.00001 8L16 16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            </button>
          </div>
          <div className="share-modal-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="search-container">
                  <div className="search-users">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder="Search users..."
                      className="search-input me-2"
                    />
                    {filteredUsers?.length > 0 && (
                      <div className="user-list">
                        {filteredUsers?.map((user) => (
                          <div
                            key={user.id}
                            className="search-result"
                            onClick={() => handleUserSelect(user)}
                          >
                            {user.name}
                            <div>{user.email}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <select className="role-select" value={selectedRole} onChange={handleRoleChange}>
                    <option value="Viewer">Viewer</option>
                    <option value="Editor">Editor</option>
                  </select>
                </div>
                <div className="selected-users">
                  <h4>Existing Users with Roles</h4>
                  <ul>
                    {usersLoaded ? (
                      selectedUsers.length > 0 ? (
                        selectedUsers.map((data, index) => (
                          <li key={data.id ? data.id : data.user.id}>
                            <div className="d-flex align-items-center gap-2">
                              <button className="close-btn" onClick={() => removeUserAccess(data.user.id, index)}>
                                <svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M19 5L4.99998 19M5.00001 5L19 19" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                              </button>
                              <div>
                                <p>{data.name ? data.name : data.user.name}</p>
                                {/* <p>{data.role}</p> */}
                                <p>{data.email ? data.email : data.user.email}</p>
                                {/* <p><b>Edit Role</b></p> */}
                              </div>
                            </div>
                            <div>
                              <select
                                className="role-select"
                                value={data.role}
                                onChange={(e) => handleRoleChangeUser(e, data)}
                              >
                                <option value="Viewer">Viewer</option>
                                <option value="Editor">Editor</option>
                              </select>
                            </div>
                          </li>
                        ))
                      ) : (
                        <p>No users found.</p>
                      )
                    ) : (
                      <div className="loader">Loading...</div>
                    )}
                  </ul>
                </div>
                {/* <div className="selected-users search">
                  <h4>Search Users</h4>
                  <ul>
                    {searchSelected?.map((data) => (
                      <li key={data.id?data.id:data.user.id}>
                        <p>
                          <b>Name:</b> {data.name?data.name:data.user.name}
                        </p>
                        <p>
                          <b>Role:</b> {data.role?data.role:data.user.role}
                        </p>
                        <p className="mb-3">
                          <b>Email:</b> {data.email?data.email:data.user.email}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
              {/* <div className="col-lg-6">
                <div className="selected-users">
                  <h4>Existing Users with Roles</h4>
                  <ul>
                    {selectedUsers?.map((data) => (
                      <li key={data.id?data.id:data.user.id}>
                          <p>
                          <b>Name:</b> {data.name?data.name:data.user.name}
                        </p>
                        <p>
                          <b>Role:</b> {data.role?data.role:data.user.role}
                        </p>
                        <p className="mb-3">
                          <b>Email:</b> {data.email?data.email:data.user.email}
                        </p>
                        <button className="btn btn-primary me-2">
                          Edit role
                        </button>
                        <button className="btn btn-danger" onClick={()=>removeUserAccess(data.id)}>
                          Remove access
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="modal-footer d-block">
          <h4 className="mb-2">General access</h4>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isDocumentPublic}
                  onChange={handleCheckboxChange}
                  id="documentPublic"
                />
                <label className="form-check-label" htmlFor="documentPublic">
                  Make document public
                </label>
              </div>
              <select className="role-select" value={publicRole} onChange={handleRoleChangePublic}>
                <option value="Viewer">Viewer</option>
                <option value="Editor">Editor</option>
              </select>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
                <button className="btn btn-outline-primary rounded-5" onClick={handleGenerateLink}>
                  Copy Link
                </button>
                <button className="btn btn-primary rounded-5" onClick={addUser1}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ShareModal;
