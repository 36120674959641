import React, { useState } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import NewLoginForm from "@/shared/components/account/loginForm/NewLoginForm";
import StorageService from "@/service/storage.service";
import AuthService from "@/service/auth.service";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
  AccountHaveAccount
} from "@/shared/components/account/AccountElements";

// const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const NewLogin = ({ history }) => {
  const [error, setError] = useState("");

  const showResults = async (data) => {
    let toastId=toast.loading('Logging In......');
    try {
      const response = await AuthService.signin({
        email: data.email,
        password: data.password,
      })
      if (response.status === 201 || response.status === 200) {
        toast.dismiss(toastId);
        if (response?.data?.token?.access) {
          toast.success(response?.data?.message);
          StorageService.userLogin(response?.data?.token?.access, response?.data?.data)
          history.push('/documents/list');
        }
      } else {
        toast.dismiss(toastId);
        toast.error(
          "Invalid email or password",
          { duration: 3000 }
        );
      }
    } catch (error) {
      if(error?.response?.data?.errors?.non_field_error) {
        setError(error?.response?.data?.errors?.non_field_error[0]);
      } else {
        toast.error(
          "Invalid email or password",
          { duration: 3000 }
        );
      }
    }
  }

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>
              Welcome to
              <AccountLogo>
                {` Document`}
                <AccountLogoAccent> AI</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
          </AccountHead>
          <NewLoginForm onSubmit={showResults} form="log_in_form" errorMessage={error} />
          <AccountHaveAccount>
            <p>
              Don't have an account? <NavLink to="/register">Signup</NavLink>
            </p>
          </AccountHaveAccount>
        </AccountCard>
      </AccountContent>
      <Toaster />
    </AccountWrap>
  )
};

export default withRouter(NewLogin);
