import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Card, CardBody } from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import styled from "styled-components";
import { colorAccent } from "@/utils/palette";
import EntityService from "@/service/entity.service"
import HistoryService from "../../../service/tablehistory.service";
import { FormContainer } from "@/shared/components/form/FormElements";
import {
  useTable, useSortBy
} from 'react-table';
import {
  useParams
} from "react-router-dom";
import ReactTableHeader from '@/shared/components/table/components/ReactTableHeader';
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    background: '#fff',
    border: '1px solid #C6BEFF',
    borderRadius: '8px'
  },
  searchInput: {
    border: 'none',
    marginLeft: 10,
    background: 'transparent',

  },
  filterDropDownIcon: {

  },
  filterDropDown: {
    position: 'relative',
    display: 'inline-block'
  },
  filterDropDownSelect: {
    appearance: 'none',
    // -webkit-appearance: 'none',
    // -moz-appearance: 'none',
    border: 'none',
    backgroundColor: '#fff',
    minWidth: 100,
    marginLeft: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },


}));

let search;
const ApiTable = ({ history }) => {
  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      width: 80,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableGlobalFilter: true,
      disableSortBy: false,
    },
    {
      Header: 'Updated_By',
      accessor: 'updated_by',
      disableSortBy: false,
    },
    {
      Header: 'Updated_At',
      accessor: 'updated_at',
      disableSortBy: false,
    },
    {
      Header: 'View History',
      disableSortBy: true,
    },
  ], []);
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  
  const classes = useStyles();
  const tableOptions = {
    columns,
    data,
    defaultColumn: {},
    dataLength: data.length,
    disableSortBy: false,
    manualSortBy: false,
  };

  const {
    headerGroups,
    state
  } = useTable(
    tableOptions,
    useSortBy,
  );


  const fetchData = async () => {
    try {
      const response = await  HistoryService.getAllDocuments({ search,sortBy, sortOrder, page: page + 1, pageSize },id);
      if(response.status==200)
      {
      setData(response?.data?.logs || []);
      setPageCount(response?.data?.total_pages || 1);
      setTotalCount(response?.data?.total_count || 0);
      setLoading(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (state.sortBy && state.sortBy.length > 0) {
      setSortOrder(state.sortBy[0].desc ? 'desc' : 'asc');

      setSortBy(state.sortBy[0].id);
    }
  }, [state]);

  //// ..................Fetch TableData.....................////
  useEffect(() => {
    fetchData();
  }, [page, pageSize, sortBy, sortOrder]);

  function formatDate(dateTimeString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateTimeString).toLocaleDateString(undefined, options);
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Table responsive striped>
                  <ReactTableHeader
                    headerGroups={headerGroups}
                    isSortable={true}
                    isResizable={false}
                  />
                  <tbody>
                    {data?.length > 0 ? (
                      data.map((items, index) => (
                        <tr key={index}>
                          <td className="table-data">{items.id}</td>
                          <td className="table-data">
                            <a
                              href={items.filepath}
                              style={{ textDecoration: "none", color: "#3ea3fc" }}
                            >
                              {items.name}
                            </a>
                          </td>
                          <td className="table-data">
                            {items.updated_by ?
                              items.updated_by.username
                              :'Not Present'}
                          </td>
                          <td className="table-data">
                            {items.updated_at ?
                             formatDate(items.timestamp)
                              :'Not Present'}
                          </td>
                          <td className="table-data">
                            <div onClick={() => history.push(`/history/view/${items.id}/${id}`)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={22} height={22}><title>eye</title><path d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" /></svg>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      data.length == 0 && !loading ?
                      <tr>
                        <td className="table-data" colSpan={10}>
                          Data Not Found
                        </td>
                      </tr>
                      :
                      <tr>
                        <td className="table-data" colSpan={10}>
                          Loading........
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end mt-3">
                  <ReactTablePagination
                    page={data}
                    gotoPage={(page) => { setPage(page); }}
                    previousPage={() => { setPage(page - 1); }}
                    nextPage={() => { setPage(page + 1); console.log((page + 1), pageCount) }}
                    canPreviousPage={page > 0}
                    canNextPage={(page + 1) < pageCount}
                    pageOptions={Array.from({ length: pageCount }, (_, i) => i)}
                    pageSize={pageSize}
                    pageIndex={page}
                    pageCount={pageCount}
                    setPageSize={(size) => { setPageSize(size); setPage(0); }}
                    manualPageSize={[10, 20]}
                    dataLength={totalCount}
                  />
                </div>
              </CardBody>

            </Card>
          </Col>
        </Row>
        <Toaster />
      </Container>
    </>
  );
};

export default ApiTable;

// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SearchWrap = styled(FormContainer)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
  }

  button {
    margin: 0;
    height: 32px;
  }
`;

export const UploadBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const Btn = styled.label`
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #624DE3;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px
`;

/* {classifyLoading && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )} */

/* {classifyLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <TableSpinner animation="border" />
                    </div>
//                   ) : ( */

// endregion
{
  /* <td className="first-letter-uppercase">
                        {items.timestamp &&
                          new Date(items.timestamp)?.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </td> */
}
