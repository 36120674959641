import React from "react";
import { Route, Switch ,Redirect} from "react-router-dom";
import MainWrapper from "../MainWrapper";
import Landing from "../../Landing/index";
import NotFound404 from "../../DefaultPage/404/index";
import LockScreen from "../../Account/LockScreen/index";
// import LogIn from "../../Account/LogIn/index";
import LogInPhoto from "../../Account/LogInPhoto/index";
import NewRegister from "../../Account/Register/NewRegister";
import RegisterPhoto from "../../Account/RegisterPhoto/index";
import ResetPassword from "../../Account/ResetPassword/index";
import ResetPasswordPhoto from "../../Account/ResetPasswordPhoto";
import WrappedRoutes from "./WrappedRoutes";
import NewLogin from "../../Account/LogIn/NewLogin";
import PrivatedRoute from "./privateRoutes";
import AuthRoute from "./authRoutes";
import storageService from "../../../service/storage.service";

const isAuthenticated = () => {
  return storageService.getToken();
};

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        {/* <Route exact path="/" component={Landing} /> */}
        {/* <Route path="/404" component={NotFound404} /> */}
        {/* <Route path="/lock_screen" component={LockScreen} /> */}
        {/* <Route path="/log_in" component={LogIn} /> */}
        <AuthRoute exact path="/register" component={NewRegister} />
        <AuthRoute path="/login" component={NewLogin} />
        {/* <Route path="/log_in_photo" component={LogInPhoto} /> */}
        {/* <Route path="/register_photo" component={RegisterPhoto} /> */}
        {/* <Route path="/reset_password" component={ResetPassword} /> */}
        {/* <Route path="/reset_password_photo" component={ResetPasswordPhoto} />  */}
        <Route
          exact
          path="/"
          render={() => (
            isAuthenticated() ? <Redirect to="/documents/list" /> : <Redirect to="/login" />
          )}
        />
        <PrivatedRoute path="/" component={WrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
