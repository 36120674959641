import axios from 'axios';
import storageService from './storage.service'; // Import your storageService for getting user data

const apiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

// Request interceptor to add authorization headers or perform other actions before sending the request
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify config headers, add authentication tokens, etc.
    // For example, add a user ID to headers if available
    const user = storageService.getUser();
    const id = user ? user.id : null;

    if (id) {
      config.headers['uid'] = id; // Add uid to headers if available
    }

    // Add referer and origin headers
    config.headers['referer'] = 'http://localhost:3000';
    config.headers['origin'] = 'http://localhost:3000';

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response interceptor to handle responses or errors globally
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      console.error('Response error:', error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
