import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from "react-hot-toast";
import { Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import {
  useParams
} from "react-router-dom";
import { useHistory } from 'react-router-dom';
import EntityService from '../../../../service/entity.service';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';

let called = false

const HorizontalForm = ({ onSubmit }) => {
  let { id } = useParams();
  const history=useHistory();
  const createDocument = async (data) => {
    const res = EntityService.createEntity(data);
    toast.promise(res, {
      loading: 'Creating',
      success: 'Entity created successfully',
      error: 'Error when updating',
    });
    setTimeout(()=>{
      history.push('/entity/list');
    },1500)
  }
  const [form, setForm] = useState({});


  const getDocument = async () => {
    let toastId
    if (!called) {
      toastId = toast.loading('Loading...');
    }
    const res = await EntityService.getEntityById(id);
    if (res?.data?.record) {
      setForm(res?.data?.record)
    }
    toast.dismiss(toastId);
  }

  const updateDocument = async (data) => {
    if(id){
    const res = EntityService.updateEntity(data,id);
    toast.promise(res, {
      loading: 'Updating',
      success: 'Entity updated successfully',
      error: 'Error when updating',
    });
    setTimeout(()=>{
      history.push('/entity/list');
    },1500)
   }else{
     createDocument(data);
   }
  }


  useEffect(() => {
    if(id)
    {
    getDocument();
    }
  }, [])

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Entity Detail</CardTitle>
          </CardTitleWrap>
          <Form onSubmit={updateDocument} initialValues={form}>
            {({ handleSubmit, form }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>Name</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Name"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">{id?'Update':'Create'}</Button>
                  {/* <Button variant="secondary" type="button" onClick={form.reset}>
                    Cancel
                  </Button> */}
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
