import React from "react";
import { Field, Form } from "react-final-form";
import { useSelector } from "react-redux";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import PasswordField from "@/shared/components/form/Password";
import FormField from '@/shared/components/form/FormField';
import {
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
} from "@/shared/components/form/FormElements";
import { AccountButton, LoginForm } from "../AccountElements";
import validate from './validate';

const NewLoginForm = ({ onSubmit, errorMessage, fieldUser, typeFieldUser }) => {
  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit }) => (
        <LoginForm
          onSubmit={handleSubmit}
        >
          <Alert variant="danger" show={!!errorMessage}>
            {errorMessage}
          </Alert>
          <FormGroup>
            <FormGroupLabel>{fieldUser}</FormGroupLabel>
            <FormGroupField>
              <FormGroupIcon>
                <AccountOutlineIcon />
              </FormGroupIcon>
              <Field
                name="email"
                component={FormField}
                type={typeFieldUser}
                placeholder={fieldUser}
                className="input-without-border-radius"
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>Password</FormGroupLabel>
            <FormGroupField>
              <Field
                name="password"
                component={PasswordField}
                placeholder="Password"
                className="input-without-border-radius"
                keyIcon
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup></FormGroup>
          <AccountButton type="submit" variant="primary">
            login
          </AccountButton>
        </LoginForm>
      )}
    </Form>
  );
};

NewLoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

NewLoginForm.defaultProps = {
  errorMessage: "",
  fieldUser: "Email",
  typeFieldUser: "text",
};

export default NewLoginForm;
