import axiosInstance from './axiosInstance.service';

const HistoryService = {
  getDocumentById: async (id) => {
    try {
      const res = await axiosInstance.get(`/classify/get_single_log/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getAllDocuments: async (params, id) => {
    try {
      const res = await axiosInstance.get(`/classify/get_record_logs/${id}`, { params });
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default HistoryService;
