import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from "react-hot-toast";
import { Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import {
  useParams
} from "react-router-dom";
import { useHistory } from 'react-router-dom';
import UserService from "../../../../service/user.service"
import storageService from '../../../../service/storage.service';
import DocumentService from '../../../../service/document.service';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { makeStyles } from '@material-ui/core';

let called = false

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    background: '#fff',
    border: '1px solid #C6BEFF',
    borderRadius: '8px'
  },
  searchInput: {
    border: 'none',
    marginLeft: 10,
    background: 'transparent',

  },
  filterDropDownIcon: {

  },
  filterDropDown: {
    position: 'relative',
    display: 'inline-block'
  },
  filterDropDownSelect: {
    appearance: 'none',
    // -webkit-appearance: 'none',
    // -moz-appearance: 'none',
    border: 'none',
    backgroundColor: '#fff',
    minWidth: 100,
    marginLeft: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },


}));
let entity;
const HorizontalForm = ({ onSubmit }) => {
  let { id } = useParams();
  const history=useHistory();
  const [role,setRole]=useState('');
  const [entities,setEntities]=useState([]);
  const [entity,setEntity]=useState(null);
  const [user,setUser]=useState(null);
  const classes = useStyles();
  const createDocument = async (data) => {
    data.role=role;
    data.entity=entity;
    if(role!='Super_Admin' && entity===null)
    {
      toast.error("Please Select the Entity For User");
      return;
    }
    const res = UserService.createUser(data);
    toast.promise(res, {
      loading: 'Creating',
      success: 'Users created successfully',
      error: 'Error when updating',
    });
    setTimeout(()=>{
      history.push('/users/list');
    },1500)
  }
  const [form, setForm] = useState({});
  const isDisabled = id?true:false; 

  const getDocument = async () => {
    let toastId
    if (!called) {
      toastId = toast.loading('Loading...');
    }
    const res = await UserService.getUserById(id);
    if (res?.status==200) {
      setForm(res?.data)
      setRole(res?.data.role);
      setEntity(res?.data.entity);
    }
    toast.dismiss(toastId);
  }

  const updateDocument = async (data) => {
    if(id){
    data.role=role;
    data.entity=entity;
    if(role!='Super_Admin' && entity===null)
    {
      toast.error("Please Select the Entity For User");
      return;
    }
    const res = UserService.updateUser(data,id);
    toast.promise(res, {
      loading: 'Updating',
      success: 'User updated successfully',
      error: 'Error when updating',
    });
    setTimeout(()=>{
      history.push('/users/list');
    },1500)
   }
   else{
     createDocument(data);
   }
  }

  console.log("-----------------",role);


  useEffect(() => {
    const addUser1=storageService.getUser();
    setUser(addUser1);
    if(addUser1.role==='Super_Admin')
    {
    getEntities();
    }
    if(id)
    {
    getDocument();
    }
  }, [])

  console.log("user---------",user);

  const getEntities = async () => {
    const res = await DocumentService.getEntities();
    console.log(res);
    if (res?.data?.entities) {
      setEntities(res?.data?.entities);
    }
  };

  const handleChangeSelect=(e)=>{
    setRole(e.target.value);
  }
  
  const handleChangeSelect1 = (event) => {
    console.log("value of event--------",event.target.value);
    let e = parseInt(event.target.value);
    if(event.target.value=='Select_Entity')
    {
      setEntity(null);
    }
    else{
      setEntity(e);
    }
    console.log("entity----",entity);
  };
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>User Detail</CardTitle>
          </CardTitleWrap>
          <Form onSubmit={updateDocument} initialValues={form}>
            {({ handleSubmit, form }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>Name</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Name"
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Email</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="email"
                      component="input"
                      type="text"
                      placeholder="Email"
                      disabled={isDisabled}
                    />
                  </FormGroupField>
                </FormGroup>
                {id?'':
                <FormGroup>
                  <FormGroupLabel>Password</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="password"
                      component="input"
                      type="text"
                      placeholder="Password"
                    />
                  </FormGroupField>
                </FormGroup>}
            {((id && (user?.role==='Super_Admin' && role!=='Super_Admin')) || (!id && role!=='Super_Admin'))  &&
              <FormGroup>
                  <FormGroupLabel>Select Entity</FormGroupLabel>
                <div className={`${classes.search} ms-3`} style={{ minWidth: 150 }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 19.88C15.04 20.18 14.94 20.5 14.71 20.71C14.6175 20.8027 14.5076 20.8762 14.3866 20.9264C14.2657 20.9766 14.136 21.0024 14.005 21.0024C13.874 21.0024 13.7444 20.9766 13.6234 20.9264C13.5024 20.8762 13.3925 20.8027 13.3 20.71L9.29001 16.7C9.18094 16.5934 9.09802 16.463 9.04771 16.319C8.9974 16.175 8.98107 16.0213 9.00001 15.87V10.75L4.21001 4.62C4.04762 4.41153 3.97434 4.14726 4.0062 3.88493C4.03805 3.6226 4.17244 3.38355 4.38001 3.22C4.57001 3.08 4.78001 3 5.00001 3H19C19.22 3 19.43 3.08 19.62 3.22C19.8276 3.38355 19.962 3.6226 19.9938 3.88493C20.0257 4.14726 19.9524 4.41153 19.79 4.62L15 10.75V19.88ZM7.04001 5L11 10.06V15.58L13 17.58V10.05L16.96 5H7.04001Z" fill="black" />
                  </svg>
                  {entities && entities.length > 0 && (
                    <select
                      className={classes.filterDropDownSelect}
                      onChange={handleChangeSelect1}
                      aria-label="Filter Option"
                      value={entity}
                    >
                      <option value={null}>Select_Entity</option>
                      {entities.map((com) => (
                        <option value={com.id}>{com.name}</option>
                      ))}
                    </select>
                    )}
                  </div> 
                  </FormGroup>}
               <FormGroup>
                  <FormGroupLabel>User Role</FormGroupLabel>
                <div className={`${classes.search} ms-3`} style={{ minWidth: 150 }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 19.88C15.04 20.18 14.94 20.5 14.71 20.71C14.6175 20.8027 14.5076 20.8762 14.3866 20.9264C14.2657 20.9766 14.136 21.0024 14.005 21.0024C13.874 21.0024 13.7444 20.9766 13.6234 20.9264C13.5024 20.8762 13.3925 20.8027 13.3 20.71L9.29001 16.7C9.18094 16.5934 9.09802 16.463 9.04771 16.319C8.9974 16.175 8.98107 16.0213 9.00001 15.87V10.75L4.21001 4.62C4.04762 4.41153 3.97434 4.14726 4.0062 3.88493C4.03805 3.6226 4.17244 3.38355 4.38001 3.22C4.57001 3.08 4.78001 3 5.00001 3H19C19.22 3 19.43 3.08 19.62 3.22C19.8276 3.38355 19.962 3.6226 19.9938 3.88493C20.0257 4.14726 19.9524 4.41153 19.79 4.62L15 10.75V19.88ZM7.04001 5L11 10.06V15.58L13 17.58V10.05L16.96 5H7.04001Z" fill="black" />
                  </svg>
                    <select className={classes.filterDropDownSelect} onChange={handleChangeSelect} aria-label="Filter Options" value={role} name='role'>
                      <option >Select Role</option>
                      {user?.role=='Super_Admin' &&
                      <option value={'Super_Admin'}>Super_Admin</option>}
                      <option value={'Manager'}>Manager</option>
                      <option value={'Accountant'}>Accountant</option>
                      <option value={'Regular_User'}>Regular_User</option>
                    </select>
                  </div> 
                  </FormGroup>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">{id?'Update':'Create'}</Button>
                  {/* <Button variant="secondary" type="button" onClick={form.reset}>
                    Cancel
                  </Button> */}
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
