import axiosInstance from './axiosInstance.service';

const documentService = {
  uploadDocument: async (data) => {
    try {
      const res = await axiosInstance.post('/doc/upload', data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  classifyDocument: async (id) => {
    try {
      const res = await axiosInstance.post('/classify/ocr-classify-service/'+ id);
      return res;
    } catch (error) {
      throw error;
    }
  },
  classifyStatus: async (id) => {
    try {
      const res = await axiosInstance.get(`/doc/validate-process-status?id=${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getDocuments: async (params) => {
    try {
      const res = await axiosInstance.get('/classify/fetch', { params });
      return res;
    } catch (error) {
      throw error;
    }
  },
  getDocumentById: async (id) => {
    try {
      const res = await axiosInstance.get(`/classify/get-prediction-record/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  deleteDocument: async (id) => {
    try {
      const res = await axiosInstance.delete(`/classify/delete-doc/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  updateDocument: async (data) => {
    try {
      const res = await axiosInstance.post('/classify/update-tags', data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getCompanies: async (company) => {
    try {
      const res = await axiosInstance.get(`/classify/fetch-companies?company=${company}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getEntities: async () => {
    try {
      const res = await axiosInstance.get('/classify/get-entities');
      return res;
    } catch (error) {
      throw error;
    }
  },

  getSelectedUsers: async (id) => {
    try {
      const res = await axiosInstance.get('/classify/document/get_record_rights/'+id);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default documentService;
