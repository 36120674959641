import React, { useState } from 'react';
import DocumentPdfViewer from './component/pdfViewer';
import toast from "react-hot-toast";
import { useEffect } from 'react';
import storageService from '../../../service/storage.service';
import documentService from '../../../service/document.service';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const App = () => {
  const history=useHistory();
  let { id } = useParams();
  const [doc,setDoc]=useState(null);
  const[isPdf,setIsPdf]=useState(true);
  // const decodedPdfUrl = "https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf";

  useEffect(()=>{
    if(storageService.getUser())
    {
    getDocument();
    }
    else{
      toast.error("Please Login to view Document");
      history.push('/login');
    }
  },[])

  const getDocument=async()=>{
    const response=await documentService.getDocumentById(id);
    if (response && response.data && response.data.record) {
      setDoc(response.data.record);
      const isPdf = response.data.record.file.endsWith('.pdf');
      setIsPdf(isPdf);
    }
    else if(response.status==403)
    {
      toast.error("You donot have access to view that document Contact with admin to view that")
      history.push('/documents/list')
    }
  
  }

  return (
    <div className='px-3 px-lg-5'>
      {doc &&<DocumentPdfViewer pdfUrl={doc} isPdf={isPdf} />}
      {doc && <h2 className='text-truncate mt-2'>{doc?.name}</h2>}
    </div>
  );
};

export default App;
