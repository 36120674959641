import { Route, Redirect } from "react-router-dom";
import StorageService from "@/service/storage.service";


const isAuthenticated = () => {
    return StorageService.getToken();
};

// GuardedRoute component to handle authentication checks
export default ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Redirect to={{ pathname: '/documents/list', state: { from: props.location } }} />
            ) : (
                <Component {...props} />
            )
        }
    />
);