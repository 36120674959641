import React from "react";
import { Route, Switch } from "react-router-dom";
import Form from "../../../Documents/form";
import PDFViewer from "../../../Documents/pdfViewer";
import Listing from "../../../Documents/listing";

export default () => (
  <Switch>
    {/* <Route path="/tables/basic_tables" component={BasicTables} />
    <Route path="/tables/data_table" component={DataTable} /> 
    <Route path="/tables/dnd_table" component={DragAndDropTable} />
    <Route path="/tables/editable_table" component={EditableTable} />
    <Route path="/tables/resizable_table" component={ResizableTable} />
    <Route path="/tables/material_table" component={MaterialTable} /> */}
    <Route path="/documents/edit/:id" component={Form} />
    <Route path="/documents/list" component={Listing} />
    <Route path="/documents/view-pdf/:id" component={PDFViewer} />
  </Switch>
);
