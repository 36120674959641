import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import { Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import storageServices from "../../../../service/storage.service"
import HistoryService from '../../../../service/tablehistory.service';
import { Document, Page } from 'react-pdf';
import {
  useParams
} from "react-router-dom";
import DocumentService from "@/service/document.service";
import { renderTagField } from '@/shared/components/form/FormField';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormGroupDescription,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import { makeStyles } from '@material-ui/core';

let called = false

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    background: '#fff',
    border: '1px solid #C6BEFF',
    borderRadius: '8px'
  },
  searchInput: {
    border: 'none',
    marginLeft: 10,
    background: 'transparent',

  },
  filterDropDownIcon: {

  },
  filterDropDown: {
    position: 'relative',
    display: 'inline-block'
  },
  filterDropDownSelect: {
    appearance: 'none',
    // -webkit-appearance: 'none',
    // -moz-appearance: 'none',
    border: 'none',
    backgroundColor: '#fff',
    minWidth: 100,
    marginLeft: 10
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },


}));

const HorizontalForm = ({ onSubmit }) => {
  let { id,doc_id } = useParams();
  const history=useHistory();
  const [form, setForm] = useState({});
  const [entities,setEntities]=useState([]);
  const [selectedEntity_id,setSelectedEnitity_id]=useState(null);
  const [loading,setLoading]=useState(true);
  const classes = useStyles();

  const getDocument = async () => {
    let toastId
    if (!called) {
      toastId = toast.loading('Loading...');
    }
    const res = await HistoryService.getDocumentById(parseInt(id));
    if (res?.data?.log) {
      setForm(res?.data?.log)
      setSelectedEnitity_id(res?.data?.log?.selectedEntity_id);
      setLoading(false);
    }
    toast.dismiss(toastId);
  }

  const getEntities = async () => {
    let toastId
    const res = await DocumentService.getEntities();
    if (res?.data?.entities) {
      setEntities(res?.data?.entities)
    }
    toast.dismiss(toastId);
  }

  const handleChangeSelect=(e)=>{
    console.log("value-------",e.target.value);
    setSelectedEnitity_id(e.target.value);
  }


  useEffect(() => {
    getDocument();
  }, [])

  useEffect(() => {
    getEntities();
  }, [])

  const handleCancel = () => {
    history.push(`/history/${doc_id}`);
  };


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardTitle>Document Detail</CardTitle>
          </CardTitleWrap>
          {!loading &&
          <Document file={'https://dmsai.s3.amazonaws.com/Documents/A&J+Couriers.pdf'}>

          </Document>
          }
          <Form onSubmit={handleCancel} initialValues={form}>
            {({ handleSubmit, form }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>Name</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="name"
                      component="input"
                      type="text"
                      placeholder="Name"
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Company</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="company"
                      component="input"
                      type="text"
                      placeholder="Company"
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Category</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="prediction"
                      component="input"
                      type="text"
                      placeholder="Category"
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Tags</FormGroupLabel>
                  <Field
                    name="tags"
                    component={renderTagField}
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>Company Entity</FormGroupLabel>
                <div className={`${classes.search} ms-3`} style={{ minWidth: 150 }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 19.88C15.04 20.18 14.94 20.5 14.71 20.71C14.6175 20.8027 14.5076 20.8762 14.3866 20.9264C14.2657 20.9766 14.136 21.0024 14.005 21.0024C13.874 21.0024 13.7444 20.9766 13.6234 20.9264C13.5024 20.8762 13.3925 20.8027 13.3 20.71L9.29001 16.7C9.18094 16.5934 9.09802 16.463 9.04771 16.319C8.9974 16.175 8.98107 16.0213 9.00001 15.87V10.75L4.21001 4.62C4.04762 4.41153 3.97434 4.14726 4.0062 3.88493C4.03805 3.6226 4.17244 3.38355 4.38001 3.22C4.57001 3.08 4.78001 3 5.00001 3H19C19.22 3 19.43 3.08 19.62 3.22C19.8276 3.38355 19.962 3.6226 19.9938 3.88493C20.0257 4.14726 19.9524 4.41153 19.79 4.62L15 10.75V19.88ZM7.04001 5L11 10.06V15.58L13 17.58V10.05L16.96 5H7.04001Z" fill="black" />
                  </svg>
                  {entities && entities.length > 0 && (
                    <select className={classes.filterDropDownSelect} aria-label="Filter Options" value={selectedEntity_id}  disabled>
                      <option value="">Select Entity</option>
                      {entities.map(com => <option value={com.id}>{com.name}</option>)}
                    </select>
                    )}
                  </div> 
                  </FormGroup>
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Back</Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
          {/* <div className={`${classes.search} ms-3`} style={{ minWidth: 150 }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 19.88C15.04 20.18 14.94 20.5 14.71 20.71C14.6175 20.8027 14.5076 20.8762 14.3866 20.9264C14.2657 20.9766 14.136 21.0024 14.005 21.0024C13.874 21.0024 13.7444 20.9766 13.6234 20.9264C13.5024 20.8762 13.3925 20.8027 13.3 20.71L9.29001 16.7C9.18094 16.5934 9.09802 16.463 9.04771 16.319C8.9974 16.175 8.98107 16.0213 9.00001 15.87V10.75L4.21001 4.62C4.04762 4.41153 3.97434 4.14726 4.0062 3.88493C4.03805 3.6226 4.17244 3.38355 4.38001 3.22C4.57001 3.08 4.78001 3 5.00001 3H19C19.22 3 19.43 3.08 19.62 3.22C19.8276 3.38355 19.962 3.6226 19.9938 3.88493C20.0257 4.14726 19.9524 4.41153 19.79 4.62L15 10.75V19.88ZM7.04001 5L11 10.06V15.58L13 17.58V10.05L16.96 5H7.04001Z" fill="black" />
                  </svg>
                  {entities && entities.length > 0 && (
                    <select className={classes.filterDropDownSelect} value={form.selectedEntity_id} onChange={handleChangeSelect} aria-label="Filter Options">
                      <option value="">Select Entity</option>
                      {entities.map(com => <option value={com.id}>{com.name}</option>)}
                    </select>
                    )}
                  </div> */}
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default HorizontalForm;
