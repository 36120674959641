import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import DeleteIcon from "mdi-react/DeleteIcon";
import PencilIcon from "mdi-react/PencilIcon";
import UpdateIcon from "mdi-react/UpdateIcon";
import EyeIcon from "mdi-react/EyeIcon";
import DownloadIcon from "mdi-react/DownloadIcon";
import EditFileDocumentIcon from "mdi-react/FileDocumentEditIcon";
import ShareVariantIcon from "mdi-react/ShareVariantIcon";
import { Card, CardBody } from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import styled from "styled-components";
import { colorAccent } from "@/utils/palette";
import DocumentService from "@/service/document.service";
import { FormContainer } from "@/shared/components/form/FormElements";
import { useTable, useSortBy } from "react-table";
import ReactTableHeader from "@/shared/components/table/components/ReactTableHeader";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import storageService from "../../../service/storage.service";
import ShareModal from "../../Layout/components/ShareModal/sharemodel";
import DeleteConfirmation from "../../Layout/components/DeleteModal/deleteModel";
import { Button } from "react-scroll";
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import DocumentPdfViewer from "../../DocumentPdf/view-rights/component/pdfViewer"
import "../listing/styles.css"

let toastId,
  documentId,
  directPage = 0;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "flex-start", // Align items to the start of the container
    justifyContent: "center", // Center items horizontally
    flexWrap: "wrap", // Allow items to wrap onto the next line
    gap: 10,
  },
  search: {
    flex: "1 0 calc(25% - 20px)", // Each item takes up 25% of the container width (minus spacing)
    padding: "10px",
    background: "#fff",
    border: "1px solid #C6BEFF",
    borderRadius: "8px",
    marginBottom: "10px", // Spacing between rows
    display: "flex",
    alignItems: "center", // Align items vertically within the search container
  },
  svgIcon: {
    width: "24px",
    height: "24px",
    marginRight: "10px", // Spacing between SVG icon and input field
  },
  searchInput: {
    border: "none",
    marginLeft: 15,
    background: "transparent",
    flex: "1", // Take remaining width
    minWidth: 0, // Allow content to overflow when needed
  },
  filterDropDownSelect: {
    appearance: "none",
    border: "none",
    backgroundColor: "#fff",
    width: "100%", // Fill the available width
    marginTop: 1, // Add spacing below the input/select
    marginLeft: 5,
  },
}));

let search,
  company = "",
  entityId = null,
  toDate = null,
  fromDate = null,
  role;
let SColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 100,
  },
  {
    Header: "File Name",
    accessor: "file",
    disableGlobalFilter: true,
    disableSortBy: false,
  },
  {
    Header: "Company",
    accessor: "company",
    disableSortBy: false,
  },
  {
    Header: "Category",
    accessor: "prediction",
    disableSortBy: false,
  },
  {
    Header: "Score",
    accessor: "probability",
    disableSortBy: false,
  },
  {
    Header: "Entity",
    accessor: "entity",
    disableSortBy: true,
  },
  {
    Header: "Upload Date",
    accessor: "timestamp",
    disableSortBy: false,
  },
  {
    Header: "Actions",
    disableSortBy: true,
  },
];

let VColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 100,
  },
  {
    Header: "File Name",
    accessor: "file",
    disableGlobalFilter: true,
    disableSortBy: false,
  },
  {
    Header: "Company",
    accessor: "company",
    disableSortBy: false,
  },
  {
    Header: "Category",
    accessor: "prediction",
    disableSortBy: false,
  },
  {
    Header: "Score",
    accessor: "probability",
    disableSortBy: false,
  },
  {
    Header: "Entity",
    accessor: "entity",
    disableSortBy: true,
  },
  {
    Header: "Upload Date",
    accessor: "timestamp",
    disableSortBy: false,
  },
  {
    Header: "Actions",
    disableSortBy: true,
  },
];
const ApiTable = ({ history }) => {
  let isPdf = false;
  const [uploadLoading, setUploadLoading] = useState(false);
  const [classifyLoading, setClassifyLoading] = useState(false);
  const [data, setData] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [sortBy, setSortBy] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [entities, setEntities] = useState([]);
  const [ToDate, setToDate] = useState(null);
  const [FromDate, setFromDate] = useState(null);
  const [lessThanAmount, setAmountLessThan] = useState(null);
  const [greaterThanAmount, setAmountGreaterThan] = useState(null);
  const [columns, setColumns] = useState([]);
  const [anomaly1, setAnomaly] = useState("All");
  const [loading, setLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [docId, setDocId] = useState(null);
  const [is_public, setIsPublic] = useState(null);
  const [publicRole, setPublicRole] = useState("");
  const [inputValue, setInputValue] = useState("1");
  const [id, setId] = useState(null);
  const [search1, setSearch] = useState("");
  const [options,setOptions]=useState([]);
  const [doc,setDoc]=useState({});
  const [companySearch,setCompanySearch]=useState('');
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const history1 = useHistory();
  const location = useLocation();
  const [isOpenPDFbox, setIsOpenPDFbox] = useState(false);
  let Message = "Are you sure you want to delete this record";

  const handleOpenPopup = (data) => {
    getSelectedUsers(data.id);
    setDocId(data.id);
    setIsPublic(data.ispublic);
    setPublicRole(data.public_role);
    setIsPopupOpen(true);
  };

  useEffect(() => {
    // Get search query from URL on initial load
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search");
    if (searchQuery) {
      setSearch(searchQuery);
      search=searchQuery;
    }
  }, []);


  console.log("company-----",companySearch);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    search=e.target.value;

    // Update URL with the search query
    const params = new URLSearchParams(location.search);
    if (value) {
      params.set("search", value);
    } else {
      params.delete("search");
    }
    history1.replace({ search: params.toString() });

    // Handle typing timeout for fetching data
    clearTimeout(typingTimeout);
    setTypingTimeout(
      setTimeout(() => {
        setPage(0);
        fetchData();
      }, 1500)
    );
  };

  const getSelectedUsers = async (id) => {
    const response = await DocumentService.getSelectedUsers(id);
    if (response.status == 200) {
      setSelectedUser(response.data.record_rights);
    }
  };

  const handleClosePopup = (is_public, publicRole) => {
    const updatedData = data.map((doc) => {
      if (doc.id === docId) {
        return { ...doc, ispublic: is_public, public_role: publicRole };
      }
      return doc;
    });
    setData(updatedData);
    setIsPopupOpen(false);
  };
  let anomaly = "All";
  //// ..................Fetch TableData.....................////
  useEffect(() => {
    let user = storageService.getUser();
    if (user) {
      role = user.role;
    }
    if (role === "Viewer") {
      setColumns(VColumns);
    } else {
      console.log(" i am working----");
      setColumns(SColumns);
    }
    fetchData();
  }, [
    refreshTable,
    page,
    pageSize,
    sortBy,
    sortOrder,
    ToDate,
    FromDate,
    lessThanAmount,
    greaterThanAmount,
  ]);

  const classes = useStyles();

  const tableOptions = {
    columns,
    data,
    defaultColumn: {},
    dataLength: data.length,
    disableSortBy: false,
    manualSortBy: false,
  };

  const { headerGroups, state } = useTable(tableOptions, useSortBy);
  const handleChangeSelect = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : "";
    company=value;
    setPage(0);
    fetchData();
  };


  const handleChangeSelect1 = (event) => {
    entityId = parseInt(event.target.value);
    if (event.target.value === "null") {
      entityId = null;
    }
    setPage(0);
    fetchData();
  };

  const handleChangeSelect2 = (event) => {
    setAnomaly(event.target.value);
    anomaly = event.target.value;
    setPage(0);
    fetchData();
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const handleFromDateChange = (date) => {
    fromDate = formatDate(date);
    console.log(fromDate);
    setFromDate(date); // `date` is a `Date` object from DatePicker
  };

  const handleToDateChange = (date) => {
    toDate = formatDate(date);
    setToDate(date); // `date` is a `Date` object from DatePicker
  };

  const handleAmountLessThanChange = (e) => {
    setAmountLessThan(e.target.value);
  };

  const handleAmountGreaterThanChange = (e) => {
    setAmountGreaterThan(e.target.value);
  };

  const getCompanies = async () => {
    try {
      const response = await DocumentService.getCompanies(companySearch);
      setCompanies(response?.data?.companies || []);
      if(response.data.companies.length>0)
        {
          setOptions(response?.data?.companies.map(com => ({ value: com, label: com })));
        }
      else{
          setOptions([]);
      } 
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchData = async () => {
    toast.loading("Loading....");
    try {
      const params = {
        page: page + 1,
        pageSize,
      };
  
      if (search !== '' && search1!== null) params.search = search;
      if (company !=='') params.company = company;
      if (entityId !=='') params.entityId = entityId;
      if (anomaly !== null) params.anomaly = anomaly;
      if (sortBy !== null) params.sortBy = sortBy;
      if (sortOrder !== null) params.sortOrder = sortOrder;
      if (toDate !== null) params.toDate = toDate;
      if (fromDate !== null) params.fromDate = fromDate;
      if (lessThanAmount !== null) params.lessThanAmount = lessThanAmount;
      if (greaterThanAmount !== null) params.greaterThanAmount = greaterThanAmount;
  
      const response = await DocumentService.getDocuments(params);
      if (response.status === 200) {
        setData(response?.data?.result || []);
        setPageCount(response?.data?.total_pages || 1);
        setTotalCount(response?.data?.total_count || 0);
        setLoading(false);
      } else {
        toast.error("Error in getting data");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      toast.dismiss();
    }
  };
  

  const clearFilter = () => {
    const params = new URLSearchParams(location.search);
    params.delete("search");
    history1.replace({ search: params.toString() });
    setSearch('');
    search='';
    company = "";
    entityId = "";
    anomaly = null;
    toDate=null;
    fromDate=null;
    setAnomaly("All");
    setPage(0);
    setToDate(null);
    setFromDate(null);
    console.log(
      search,
      company,
      entityId,
      anomaly,
      anomaly1,
      page,
      ToDate,
      FromDate
    );
      fetchData();
  };

  ////////...................Upload Document Handling................./////////
  const handleChange = async (e) => {
    const file = e.target.files[0];
    // setSelectedFile(file);
    const formData = new FormData();
    formData.append("file", file);
    setUploadLoading(true);
    try {
      const res = await DocumentService.uploadDocument(formData);

      if (res.status === 201 || res.status === 200) {
        toast.success(res?.data.message, { duration: 2000 });
        handleClassifyDocument(res.data.file_details.id);
      }
    } catch (error) {
      toast.error("Error during file upload:");
    } finally {
      setUploadLoading(false);
    }
  };

  const uploadHandleChange = async (e) => {
    const file = e.target.files[0];
    // setSelectedFile(file);
    const formData = new FormData();
    formData.append("file", file);
    setUploadLoading(true);
    try {
      const res = await DocumentService.uploadDocument(formData);

      if (res.status === 201 || res.status === 200) {
        toast.success(res?.data.message, { duration: 2000 });
      }
    } catch (error) {
      toast.error("Error during file upload:");
    } finally {
      setUploadLoading(false);
    }
  };

  const getEntities = async () => {
    const res = await DocumentService.getEntities();
    if (res?.data?.entities) {
      setEntities(res?.data?.entities);
    }
    console.log("entities------", entities);
  };

  const downloadFile = (item) => {
    window.open(item.filepath);
  };

  ////////...................Classify Document Handling................./////////
  const handleClassifyDocument = async (id) => {
    setClassifyLoading(true);

    try {
      toastId = toast.loading(
        "Classification is in progress. It will take 2-3 min"
      );
      classificationStatus(id);
      const response = await DocumentService.classifyDocument(id);

      if (response.status === 201 || response.status === 200) {
        toast.dismiss(toastId);
        if (response?.data?.success === false) {
          toast.error(response?.data?.message);
        } else {
          toast.success(response?.data?.message);
        }
        setRefreshTable((prevRefreshTable) => !prevRefreshTable);
      }
    } catch (error) {
      // toast.error(error?.message);
    } finally {
      setClassifyLoading(false);
    }
  };

  const classificationStatus = async (id) => {
    const response = await DocumentService.classifyStatus(id);
    if (response.status === 201 || response.status === 200) {
      if (response?.data?.status === "processing") {
        setTimeout(() => {
          classificationStatus(id);
        }, 4000);
      } else if (response?.data?.status === "error") {
        toast.dismiss(toastId);
        toast.error("Error: Classification not completed");
      } else {
        toast.dismiss(toastId);
        toast.success("Classification completed");
        fetchData();
        getCompanies();
      }
    }
  };

  useEffect(() => {
    let user = storageService.getUser();
    if (user.role === "Super_Admin") {
      getEntities();
    }
  }, []);

  useEffect(() => {
    getCompanies();
  }, [companySearch]);

  useEffect(() => {
    if (state.sortBy && state.sortBy.length > 0) {
      // const newSortOrder = state.sortBy[0].desc ? "desc" : "asc";
      sortOrder==='desc' ? setSortOrder('asc') : setSortOrder('desc');
      const newSortBy = state.sortBy[0].id;
      // Use functional updates to ensure the latest state is used
      // setSortOrder((prevSortOrder) => {
      //   if (newSortOrder !== prevSortOrder) {
      //     return newSortOrder;
      //   }
      //   return prevSortOrder;
      // });
  
      setSortBy((prevSortBy) => {
        if (newSortBy !== prevSortBy) {
          return newSortBy;
        }
        return prevSortBy;
      });
    }
  }, [state.sortBy])

  const showDeleteModal = (id) => {
    setDisplayConfirmationModal(true);
    setId(id);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleDelete = async () => {
    let toastId = toast.loading("Loading...");
    hideConfirmationModal();
    try {
      const res = await DocumentService.deleteDocument(id);
      if (res?.status === 204) {
        // Remove the deleted entity from entities state
        setData((prevEntities) =>
          prevEntities.filter((record) => record.id !== id)
        );
        toast.dismiss(toastId);
        toast.success("Record deleted successfully");
      } else {
        toast.dismiss(toastId);
        toast.error("Failed to delete Record");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const isPdfUrl = (url) => {
    // Get the file extension from the URL
    const extension = url.split(".").pop().toLowerCase();
    return extension === "pdf" || extension === "docx";
  };

  const HandleSearchChange = (e) => {
    search = e.target.value;
  };

  const gotoPage = (page) => {
    setPage(page);
    setInputValue(page + 1);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    const newPage = Number(inputValue) - 1;
    if (!isNaN(newPage) && newPage >= 0 && newPage < pageCount) {
      gotoPage(newPage);
    } else {
      if (newPage >= pageCount) {
        setInputValue(String(pageCount));
        gotoPage(pageCount - 1);
        toast.success(`Total pages are: ${pageCount}`);
      } else if (newPage < 0) {
        setInputValue("1");
        gotoPage(0);
        toast.error("Page cannot be smaller than 1");
      } else {
        setInputValue(String(page + 1));
        gotoPage(page);
      }

    }
  };
  console.log("pagecount",pageCount);
  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") {
      const newPage = Number(inputValue) - 1;
      if (!isNaN(newPage) && newPage >= 0 && newPage < pageCount) {
        gotoPage(newPage);
      } else {
        if (newPage >= pageCount) {
          setInputValue(String(pageCount));
          gotoPage(pageCount - 1);
          toast.success(`Total pages are: ${pageCount}`);
        } else if (newPage < 0) {
          setInputValue("1");
          gotoPage(0); 
          toast.error("Page cannot be smaller than 1");
        } else {
          setInputValue(String(page + 1));
          gotoPage(page); 
        }
      }
    }
  };

  const openPDFbox = (data) => {
    setIsOpenPDFbox(true);
    setDoc(data); 
  };

  const closePDFbox = () => {
    setIsOpenPDFbox(false);
  };

  const handleInputChangeSearch = (inputValue, { action }) => {
    if (action === 'input-change') {
      setCompanySearch(inputValue);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <div className={classes.search}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 14L11.0093 11.004L14 14ZM12.6667 7C12.6667 8.50289 12.0697 9.94423 11.0069 11.0069C9.94424 12.0696 8.5029 12.6667 7.00001 12.6667C5.49712 12.6667 4.05578 12.0696 2.99307 11.0069C1.93037 9.94423 1.33334 8.50289 1.33334 7C1.33334 5.4971 1.93037 4.05576 2.99307 2.99306C4.05578 1.93035 5.49712 1.33333 7.00001 1.33333C8.5029 1.33333 9.94424 1.93035 11.0069 2.99306C12.0697 4.05576 12.6667 5.4971 12.6667 7V7Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                type="text"
                id="search"
                placeholder="Search"
                className={classes.searchInput}
                value={search1}
                onChange={handleSearchChange}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={`${classes.search}`} style={{ minWidth: 150 }}>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <rect width="24" height="24" fill="white"></rect>{" "}
                  <path
                    d="M17 9.5L12 14.5L7 9.5"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              {(
                <Select
                className={classes.filterDropDownSelect}
                classNamePrefix="react-select"
                value={company}
                onChange={handleChangeSelect}
                onInputChange={handleInputChangeSearch}
                options={options}
                placeholder="Select Company"
                isClearable
                aria-label="Filter Options"
                />
              )}
            </div>
          </Col>
          {role == "Super_Admin" && (
            <Col xs={12} md={3}>
              <div className={`${classes.search}`} style={{ minWidth: 150 }}>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <rect width="24" height="24" fill="white"></rect>{" "}
                    <path
                      d="M17 9.5L12 14.5L7 9.5"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
                {entities && entities.length > 0 && (
                  <select
                    className={classes.filterDropDownSelect}
                    onChange={handleChangeSelect1}
                    aria-label="Filter Option"
                    value={entityId}
                  >
                    <option value="">Select Entity</option>
                    {entities.map((com) => (
                      <option value={com.id}>{com.name}</option>
                    ))}
                  </select>
                )}
              </div>
            </Col>
          )}
          <Col xs={12} md={3}>
            <div className={`${classes.search}`} style={{ minWidth: 150 }}>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <rect width="24" height="24" fill="white"></rect>{" "}
                  <path
                    d="M17 9.5L12 14.5L7 9.5"
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              <select
                className={classes.filterDropDownSelect}
                onChange={handleChangeSelect2}
                aria-label="Filter Option"
                value={anomaly1}
              >
                <option value={"All"}>All Documents</option>
                <option value={"Anomaly"}>Anomaly Detection</option>
              </select>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={classes.search}>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <rect width="24" height="24" fill="white"></rect>{" "}
                  <g filter="url(#filter0_d_15_286)">
                    {" "}
                    <path
                      d="M3 8.26667V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V8.26667M3 8.26667V5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5V8.26667M3 8.26667H21"
                      stroke="#000000"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>{" "}
                  <g filter="url(#filter1_d_15_286)">
                    {" "}
                    <circle
                      cx="12"
                      cy="14"
                      r="2"
                      stroke="#000000"
                      stroke-linejoin="round"
                    ></circle>{" "}
                  </g>{" "}
                  <g filter="url(#filter2_d_15_286)">
                    {" "}
                    <path
                      d="M7 2V5"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>{" "}
                  <g filter="url(#filter3_d_15_286)">
                    {" "}
                    <path
                      d="M17 2V5"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>{" "}
                  <defs>
                    {" "}
                    <filter
                      id="filter0_d_15_286"
                      x="1.5"
                      y="3.5"
                      width="21"
                      height="19"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                    <filter
                      id="filter1_d_15_286"
                      x="8.5"
                      y="11.5"
                      width="7"
                      height="7"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                    <filter
                      id="filter2_d_15_286"
                      x="5.5"
                      y="1.5"
                      width="3"
                      height="6"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                    <filter
                      id="filter3_d_15_286"
                      x="15.5"
                      y="1.5"
                      width="3"
                      height="6"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                  </defs>{" "}
                </g>
              </svg>
              <DatePicker
                selected={FromDate}
                onChange={handleFromDateChange}
                className="border-0 ms-2"
                id="fromDate"
                dateFormat="yyyy-MM-dd"
                placeholderText="From Date"
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={classes.search}>
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <rect width="24" height="24" fill="white"></rect>{" "}
                  <g filter="url(#filter0_d_15_286)">
                    {" "}
                    <path
                      d="M3 8.26667V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V8.26667M3 8.26667V5C3 4.44772 3.44772 4 4 4H20C20.5523 4 21 4.44772 21 5V8.26667M3 8.26667H21"
                      stroke="#000000"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>{" "}
                  <g filter="url(#filter1_d_15_286)">
                    {" "}
                    <circle
                      cx="12"
                      cy="14"
                      r="2"
                      stroke="#000000"
                      stroke-linejoin="round"
                    ></circle>{" "}
                  </g>{" "}
                  <g filter="url(#filter2_d_15_286)">
                    {" "}
                    <path
                      d="M7 2V5"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>{" "}
                  <g filter="url(#filter3_d_15_286)">
                    {" "}
                    <path
                      d="M17 2V5"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>{" "}
                  <defs>
                    {" "}
                    <filter
                      id="filter0_d_15_286"
                      x="1.5"
                      y="3.5"
                      width="21"
                      height="19"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                    <filter
                      id="filter1_d_15_286"
                      x="8.5"
                      y="11.5"
                      width="7"
                      height="7"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                    <filter
                      id="filter2_d_15_286"
                      x="5.5"
                      y="1.5"
                      width="3"
                      height="6"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                    <filter
                      id="filter3_d_15_286"
                      x="15.5"
                      y="1.5"
                      width="3"
                      height="6"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      {" "}
                      <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>{" "}
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>{" "}
                      <feOffset dy="1"></feOffset>{" "}
                      <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>{" "}
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                      ></feColorMatrix>{" "}
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_15_286"
                      ></feBlend>{" "}
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_15_286"
                        result="shape"
                      ></feBlend>{" "}
                    </filter>{" "}
                  </defs>{" "}
                </g>
              </svg>
              <DatePicker
                selected={ToDate}
                onChange={handleToDateChange}
                className="border-0 ms-2"
                id="toDate"
                dateFormat="yyyy-MM-dd"
                placeholderText="To Date"
              />
            </div>
          </Col>
          {/* <Col xs={12} md={3}>
            <div className={classes.search}>
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 11V15M18 9V13M17 4C19.4487 4 20.7731 4.37476 21.4321 4.66544C21.5199 4.70415 21.5638 4.72351 21.6904 4.84437C21.7663 4.91682 21.9049 5.12939 21.9405 5.22809C22 5.39274 22 5.48274 22 5.66274V16.4111C22 17.3199 22 17.7743 21.8637 18.0079C21.7251 18.2454 21.5914 18.3559 21.3319 18.4472C21.0769 18.5369 20.562 18.438 19.5322 18.2401C18.8114 18.1017 17.9565 18 17 18C14 18 11 20 7 20C4.55129 20 3.22687 19.6252 2.56788 19.3346C2.48012 19.2958 2.43624 19.2765 2.3096 19.1556C2.23369 19.0832 2.09512 18.8706 2.05947 18.7719C2 18.6073 2 18.5173 2 18.3373L2 7.58885C2 6.68009 2 6.2257 2.13628 5.99214C2.2749 5.75456 2.40859 5.64412 2.66806 5.55281C2.92314 5.46305 3.43803 5.56198 4.46783 5.75985C5.18862 5.89834 6.04348 6 7 6C10 6 13 4 17 4ZM14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z" stroke="#000000" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              <input
                type="number"
                className="border-0 ms-2"
                id="amountLessThan"
                placeholder="Amount Less Than"
                value={lessThanAmount}
                onChange={handleAmountLessThanChange}
              />
            </div>
          </Col>
          <Col xs={12} md={3}>
            <div className={classes.search}>
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 11V15M18 9V13M17 4C19.4487 4 20.7731 4.37476 21.4321 4.66544C21.5199 4.70415 21.5638 4.72351 21.6904 4.84437C21.7663 4.91682 21.9049 5.12939 21.9405 5.22809C22 5.39274 22 5.48274 22 5.66274V16.4111C22 17.3199 22 17.7743 21.8637 18.0079C21.7251 18.2454 21.5914 18.3559 21.3319 18.4472C21.0769 18.5369 20.562 18.438 19.5322 18.2401C18.8114 18.1017 17.9565 18 17 18C14 18 11 20 7 20C4.55129 20 3.22687 19.6252 2.56788 19.3346C2.48012 19.2958 2.43624 19.2765 2.3096 19.1556C2.23369 19.0832 2.09512 18.8706 2.05947 18.7719C2 18.6073 2 18.5173 2 18.3373L2 7.58885C2 6.68009 2 6.2257 2.13628 5.99214C2.2749 5.75456 2.40859 5.64412 2.66806 5.55281C2.92314 5.46305 3.43803 5.56198 4.46783 5.75985C5.18862 5.89834 6.04348 6 7 6C10 6 13 4 17 4ZM14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z" stroke="#000000" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
              <input
                type="number"
                className="border-0 ms-2"
                id="amountGreaterThan"
                placeholder="Amount Greater Than"
                value={greaterThanAmount}
                onChange={handleAmountGreaterThanChange}
              />
            </div>
          </Col> */}
          <Col xs={12} className="my-3">
            <Box sx={{ display: "flex", gap: "10px" }}>
              <UploadBtn style={{ justifyContent: "start", width: "auto" }}>
                <Btn htmlFor="file" style={{ width: 200 }}>
                  {uploadLoading ? (
                    <CircularProgress color="white" size={18} />
                  ) : (
                    "Upload & Classify Document"
                  )}
                </Btn>
                <input
                  type="file"
                  onChange={handleChange}
                  style={{ display: "none" }}
                  id="file"
                  multiple
                />
              </UploadBtn>
              {/* <Button variant="outline-secondary" onClick={clearFilter}><span className="lnr lnr-pushpin" /> Clear Filter</Button> */}
              <button
                className="btn btn-outline-info"
                onClick={clearFilter}
                style={{ padding: "8px 12px", height: 40 }}
              >
                Clear Filter
              </button>
            </Box>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Table responsive striped>
                  <ReactTableHeader
                    headerGroups={headerGroups}
                    isSortable={true}
                    isResizable={false}
                  />
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((items, index) => (
                        <tr key={index}>
                          <td className="table-data">{items.id}</td>
                          <td className="table-data">
                            <a
                              href={items.filepath}
                              style={{
                                textDecoration: "none",
                                color: "#3ea3fc",
                              }}
                            >
                              {items?.name ? items?.name : items?.file}
                            </a>
                          </td>
                          {items.company ? (
                            <td className="table-data">{items.company}</td>
                          ) : (
                            <td
                              className="table-data"
                              style={{ color: "red" }}
                              title="Company not found"
                            >
                              1 Anomaly Detected
                            </td>
                          )}
                          <td className="table-data">{items.prediction}</td>
                          <td className="table-data">{items.probability}</td>
                          <td className="table-data">
                            {items?.selectedEntity?.name || "N/A"}
                          </td>
                          <td className="table-data">
                            {items.timestamp &&
                              new Date(items.timestamp)?.toLocaleString(
                                undefined,
                                {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                          </td>

                          <td
                            className="table-data"
                            style={{
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <>
                              <div
                                title="Share Document"
                                onClick={() => handleOpenPopup(items)}
                                style={{ padding: 5, cursor:'pointer' }}
                              >
                                <ShareVariantIcon />
                              </div>
                              <div
                                style={{ padding: 5 , cursor:'pointer'  }}
                                title="Edit Details"
                                onClick={() =>
                                  history.push(`/documents/edit/${items.id}`)
                                }
                              >
                                <PencilIcon />
                              </div>
                              {isPdfUrl(items.filepath) && (
                                <div
                                  style={{ padding: 5 , cursor:'pointer' }}
                                  title="Edit Document"
                                >
                                  <EditFileDocumentIcon
                                    // style={{ marginLeft: 5, marginTop: 5, cursor: "pointer" }}
                                    onClick={() => {
                                      history.push(`view-pdf/${items?.id}`);
                                      // window.location.href = `${'http://localhost'}:9001/p/${items?.name ? items?.name : items?.file}?filePath=${items.filepath}&fileName=${items?.name ? items?.name : items?.file}`
                                    }}
                                  />
                                </div>
                              )}
                              <div 
                              // onClick={() => history.push(`/document/view-rights/${items.id}`)} 
                              onClick={() => openPDFbox(items)}
                              style={{ padding: 5 }} title="View Document"
                              >
                                  <EyeIcon />
                                </div>
                              <div
                                onClick={() => downloadFile(items)}
                                style={{ padding: 5 , cursor:'pointer' }}
                                title="Download Document"
                              >
                                <DownloadIcon />
                              </div>
                              <div
                                style={{ padding: 5 , cursor:'pointer' }}
                                title="Delete Document"
                                onClick={() => showDeleteModal(items.id)}
                              >
                                <DeleteIcon />
                              </div>
                            </>
                            <div
                              style={{ padding: 5 , cursor:'pointer'  }}
                              title="View History"
                              onClick={() =>
                                history.push(`/history/${items.id}`)
                              }
                            >
                              <UpdateIcon />
                            </div>

                            {/* {isPdfUrl(items.filepath) && <EditFileDocumentIcon style={{ marginLeft: 5, marginTop: 5, cursor: "pointer" }} onClick={() => { 
                              history.push(`view-pdf/${items?.id}`) 
                              // window.location.href = `${'http://localhost'}:9001/p/${items?.name ? items?.name : items?.file}?filePath=${items.filepath}&fileName=${items?.name ? items?.name : items?.file}` 
                            }} />} */}
                          </td>
                        </tr>
                      ))
                    ) : data.length == 0 && !loading ? (
                      <tr>
                        <td className="table-data" colSpan={10}>
                          Data Not Found
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="table-data" colSpan={10}>
                          Loading........
                        </td>
                      </tr>
                    )}
                    {/* <tr>
                      {classifyLoading && (
                        <td colSpan="4">
                          <div className="text-center">
                            <TableSpinner animation="border" />
                          </div>
                        </td>
                      )}
                    </tr> */}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end mt-3 gap-3">
                  <ReactTablePagination
                    page={data}
                    gotoPage={(page) => {
                      setPage(page);
                    }}
                    previousPage={() => {
                      setPage(page - 1);
                    }}
                    nextPage={() => {
                      setPage(page + 1);
                      console.log(page + 1, pageCount);
                    }}
                    canPreviousPage={page > 0}
                    canNextPage={page + 1 < pageCount}
                    pageOptions={Array.from({ length: pageCount }, (_, i) => i)}
                    pageSize={pageSize}
                    pageIndex={page}
                    pageCount={pageCount}
                    setPageSize={(size) => {
                      setPageSize(size);
                      setPage(0);
                    }}
                    manualPageSize={[10, 20]}
                    dataLength={totalCount}
                  />
                  <input
                    type="number"
                    className="form-control"
                    style={{ width: 64, height: 30 }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onKeyPress={handleInputKeyPress}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Toaster />
      </Container>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        message={Message}
      />
      <ShareModal
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        Users={selectedUser}
        doc_id={docId}
        isPublic={is_public}
        public_role={publicRole}
      />
      {/* <Modal
        show={isOpenPDFbox}
        onHide={closePDFbox}
        dialogClassName="modal-90w"
        size="lg"
        fullscreen="true"
      >
        <div className="modal__body">
          <div className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              aria-label="close lightbox button"
              onClick={closePDFbox}
            />
          </div>
          {doc &&<pdfViewer pdfUrl={doc} isPdf={doc?.file?.endsWith('.pdf')?true:false} />}
          {doc && <h2 className='text-truncate mt-2'>{doc?.name}</h2>}
        </div>
      </Modal> */}


      <Modal
        size="lg"
        show={isOpenPDFbox}
        onHide={closePDFbox}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="modal-90w"
        centered
        fullscreen="true"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            {doc &&<DocumentPdfViewer pdfUrl={doc} isPdf={doc?.file?.endsWith('.pdf')?true:false} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApiTable;

// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SearchWrap = styled(FormContainer)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
  }

  button {
    margin: 0;
    height: 32px;
  }
`;

export const UploadBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const Btn = styled.label`
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #624de3;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
`;

/* {classifyLoading && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )} */

/* {classifyLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <TableSpinner animation="border" />
                    </div>
//                   ) : ( */

// endregion
{
  /* <td className="first-letter-uppercase">
                        {items.timestamp &&
                          new Date(items.timestamp)?.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </td> */
}
