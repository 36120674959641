import React from "react";
import { Route, Switch } from "react-router-dom";
import Form from "../../../Document_History/form";
import Listing from "../../../Document_History/listing";

export default () => (
  <Switch>
    <Route path="/history/view/:id/:doc_id" component={Form} />
    <Route path="/history/:id" component={Listing} />
  </Switch>
);
