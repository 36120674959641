import React, { useEffect, useState, useMemo } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import PencilIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import { Card, CardBody } from "@/shared/components/Card";
import { Table } from "@/shared/components/TableElements";
import styled from "styled-components";
import { colorAccent } from "@/utils/palette";
import DocumentService from "@/service/document.service";
import EntityService from "@/service/entity.service";
import { FormContainer } from "@/shared/components/form/FormElements";
import { useTable, useSortBy } from "react-table";
import ReactTableHeader from "@/shared/components/table/components/ReactTableHeader";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import toast, { Toaster } from "react-hot-toast";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import storageService from "../../../service/storage.service";
import DeleteConfirmation from "../../Layout/components/DeleteModal/deleteModel";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  search: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    background: "#fff",
    border: "1px solid #C6BEFF",
    borderRadius: "8px",
  },
  searchInput: {
    border: "none",
    marginLeft: 10,
    background: "transparent",
  },
  filterDropDownIcon: {},
  filterDropDown: {
    position: "relative",
    display: "inline-block",
  },
  filterDropDownSelect: {
    appearance: "none",
    // -webkit-appearance: 'none',
    // -moz-appearance: 'none',
    border: "none",
    backgroundColor: "#fff",
    minWidth: 100,
    marginLeft: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
let search, role;

let SColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 80,
  },
  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
    disableSortBy: false,
  },
  {
    Header: "Created Date",
    accessor: "timestamp",
    disableSortBy: false,
  },
  {
    Header: "Edit",
    disableSortBy: true,
    show: role === "Editor" || role == "Super_Admin",
  },
  {
    Header: "Delete",
    disableSortBy: true,
    show: role === "Editor" || role == "Super_Admin",
  },
];

let VColumns = [
  {
    Header: "ID",
    accessor: "id",
    width: 80,
  },
  {
    Header: "Name",
    accessor: "name",
    disableGlobalFilter: true,
    disableSortBy: false,
  },
  {
    Header: "Created Date",
    accessor: "timestamp",
    disableSortBy: false,
  },
];

//// ..................Fetch TableData.....................////
const ApiTable = ({ history }) => {
  const [data, setData] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [sortBy, setSortBy] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [id,setId]=useState(null);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [loading,setLoading]=useState(true);
  let Message='Are you sure you want to delete this Entity';
  useEffect(() => {
    let user = storageService.getUser();
    if (user) {
      role = user.role;
    }
    if (role === "Viewer") {
      setColumns(VColumns);
    } else {
      setColumns(SColumns);
    }
    fetchData();
  }, [page, pageSize, sortBy, sortOrder]);

   // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id) => {
    setDisplayConfirmationModal(true);
    setId(id);
  };
 
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const classes = useStyles();
  const tableOptions = {
    columns,
    data,
    defaultColumn: {},
    dataLength: data.length,
    disableSortBy: false,
    manualSortBy: false,
  };

  const { headerGroups, state } = useTable(tableOptions, useSortBy);

  const handleDelete = async () => {
    let toastId = toast.loading('Loading...');
    hideConfirmationModal();
    try {
      const res = await EntityService.deleteEntity(id);
      if (res?.status === 200) {
        // Remove the deleted entity from entities state
        setData((prevEntities) =>
          prevEntities.filter((entity) => entity.id !== id)
        );
        toast.dismiss(toastId);
        toast.success("Entity deleted successfully");
      } else {
        toast.error("Failed to delete entity");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    try {
      const response = await EntityService.getEntities({
        search,
        sortBy,
        sortOrder,
        page: page + 1,
        pageSize,
      });
      if(response.status==200)
      {
      setData(response?.data?.entities || []);
      setPageCount(response?.data?.total_pages || 1);
      setTotalCount(response?.data?.total_count || 0);
      setLoading(false);
      }
      else{
        toast.error("Error in getting response");
        setLoading(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (state.sortBy && state.sortBy.length > 0) {
      setSortOrder(state.sortBy[0].desc ? "desc" : "asc");

      setSortBy(state.sortBy[0].id);
    }
  }, [state]);

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "20px",
            gap: "5px",
          }}
        >
          <div className={classes.wrapper} noValidate autoComplete="off">
            <div className={classes.search}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 14L11.0093 11.004L14 14ZM12.6667 7C12.6667 8.50289 12.0697 9.94423 11.0069 11.0069C9.94424 12.0696 8.5029 12.6667 7.00001 12.6667C5.49712 12.6667 4.05578 12.0696 2.99307 11.0069C1.93037 9.94423 1.33334 8.50289 1.33334 7C1.33334 5.4971 1.93037 4.05576 2.99307 2.99306C4.05578 1.93035 5.49712 1.33333 7.00001 1.33333C8.5029 1.33333 9.94424 1.93035 11.0069 2.99306C12.0697 4.05576 12.6667 5.4971 12.6667 7V7Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input
                type="text"
                id="search"
                placeholder="Search"
                className={classes.searchInput}
                onChange={(e) => {
                  search = e.target.value;

                  clearTimeout(typingTimeout);

                  setTypingTimeout(
                    setTimeout(() => {
                      // Call your API function here after 3 seconds of no typing
                      setPage(0);
                      fetchData();
                    }, 1500)
                  );
                }}
              />
            </div>
          </div>

          {role == "Editor" ||
            (role == "Super_Admin" && (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Btn
                  htmlFor="file"
                  style={{ width: 200 }}
                  onClick={() => history.push("/entity/create")}
                >
                  Create Entity
                </Btn>
              </Box>
            ))}
        </Box>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                <Table responsive striped>
                  <ReactTableHeader
                    headerGroups={headerGroups}
                    isSortable={true}
                    isResizable={false}
                  />
                  <tbody>
                    {data?.length > 0 ? (
                      data?.map((items, index) => (
                        <tr key={index}>
                          <td className="table-data">{items.id}</td>
                          <td className="table-data">
                            <a
                              href={items.filepath}
                              style={{
                                textDecoration: "none",
                                color: "#3ea3fc",
                              }}
                            >
                              {items.name}
                            </a>
                          </td>
                          <td className="table-data">
                            {items.timestamp &&
                              new Date(items.timestamp)?.toLocaleString(
                                undefined,
                                {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                          </td>
                          {role == "Editor" ||
                            (role == "Super_Admin" && (
                              <td className="table-data">
                                <div
                                  onClick={() =>
                                    history.push(`/entity/edit/${items.id}`)
                                  }
                                >
                                  <PencilIcon />
                                </div>
                              </td>
                            ))}
                          {role == "Editor" ||
                            (role == "Super_Admin" && (
                              <td
                                className="table-data"
                                onClick={() => showDeleteModal(items.id)}
                              >
                                <div>
                                  <DeleteIcon />
                                </div>
                              </td>
                            ))}
                        </tr>
                      ))
                    ) : (
                      data.length==0 && !loading ?
                      <tr>
                        <td className="table-data" colSpan={7}>
                          Data Not Found
                        </td>
                      </tr>
                      :
                      <tr>
                      <td className="table-data" colSpan={7}>
                        Loading.......
                      </td>
                    </tr>
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end mt-3">
                  <ReactTablePagination
                    page={data}
                    gotoPage={(page) => {
                      setPage(page);
                    }}
                    previousPage={() => {
                      setPage(page - 1);
                    }}
                    nextPage={() => {
                      setPage(page + 1);
                      console.log(page + 1, pageCount);
                    }}
                    canPreviousPage={page > 0}
                    canNextPage={page + 1 < pageCount}
                    pageOptions={Array.from({ length: pageCount }, (_, i) => i)}
                    pageSize={pageSize}
                    pageIndex={page}
                    pageCount={pageCount}
                    setPageSize={(size) => {
                      setPageSize(size);
                      setPage(0);
                    }}
                    manualPageSize={[10, 20]}
                    dataLength={totalCount}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Toaster />
        <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDelete} hideModal={hideConfirmationModal} message={Message}  />
      </Container>
    </>
  );
};

export default ApiTable;

// region STYLES

const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;

const SearchWrap = styled(FormContainer)`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;

  input {
    margin-right: 10px;
  }

  button {
    margin: 0;
    height: 32px;
  }
`;

export const UploadBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const Btn = styled.label`
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #624de3;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
`;

/* {classifyLoading && (
                <div className="text-center">
                  <TableSpinner animation="border" />
                </div>
              )} */

/* {classifyLoading ? (
                    <div
                      className="text-center"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <TableSpinner animation="border" />
                    </div>
//                   ) : ( */

// endregion
{
  /* <td className="first-letter-uppercase">
                        {items.timestamp &&
                          new Date(items.timestamp)?.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                      </td> */
}
