import axiosInstance from './axiosInstance.service';

const EntityService = {
  getUserById: async (id) => {
    try {
      const res = await axiosInstance.get(`/user/get_single_user/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (data, id) => {
    try {
      const res = await axiosInstance.put(`/user/update/${id}`, data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  createUser: async (data) => {
    try {
      const res = await axiosInstance.post(`/user/create`, data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async (id) => {
    try {
      const res = await axiosInstance.delete(`/user/delete/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getUsers: async (params) => {
    try {
      const res = await axiosInstance.get(`/user/get_all_Users`, { params });
      return res;
    } catch (error) {
      throw error;
    }
  },
  addUserDocumentRole: async (id,data) => {
    try {
      const res = await axiosInstance.post(`/classify/document/access-rights/`+ id,data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  removeUserAccess: async (doc_id,id) => {
    try {
      const res = await axiosInstance.delete(`/classify/document/delete-record/${doc_id}/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  editUserAccess: async (id,user_id,data) => {
    try {
      const res = await axiosInstance.put(`/classify/document/update-role/${id}/${user_id}`,data);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default EntityService;
