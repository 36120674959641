import React from "react";
import { Route, Switch } from "react-router-dom";
import Form from "../../../Users/form";
import Listing from "../../../Users/listing";

export default () => (
  <Switch>
    <Route path="/users/edit/:id" component={Form} />
    <Route path="/users/create" component={Form} />
    <Route path="/users/list" component={Listing} />
  </Switch>
);
