const storageService = {
    userLogin: (token, user) => {
        localStorage.setItem('user', JSON.stringify(user));
        return localStorage.setItem('token', token);
    },
    logout: () => {
        localStorage.removeItem('user');
        return localStorage.removeItem('token');
    },
    getToken: () => {
        return localStorage.getItem('token');
    },
    getUser: () => {
        let user = localStorage.getItem('user');
        if(user) {
            return JSON.parse(user);
        } else {
            return false;
        }
    },
};

export default storageService;