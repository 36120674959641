import React, { useState, useEffect, useRef } from "react";
import './pdfViewer.css';
import { registerLicense } from '@syncfusion/ej2-base';
import StorageService from "@/service/storage.service";
import { PdfViewerComponent, Toolbar, Annotation, Inject} from '@syncfusion/ej2-react-pdfviewer';
import { useParams, useHistory } from 'react-router-dom';
import documentService from "../../../../service/document.service";
import toast from "react-hot-toast";

let saveAnnotations = false;
function Viewer() {
  registerLicense(process.env.REACT_APP_PDFVIEWER_LISENCE);
  const { id } = useParams();
  const [isPdf, setIsPdf]=useState(false);
  const [user, setUser] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [record, setRecord] = useState({});
  const [exportObject, setExportObject] = useState([]);
  const pdfViewerRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const storageUser = StorageService.getUser();
    setUser(storageUser);
  }, [user])

  async function getRecordById() {
    const res = await documentService.getDocumentById(id);
    setRecord(res.data.record);
    setExportObject(res.data.record?.annotations)
    const shouldImportAnnotations = isPdfUrl(res.data.record?.file);
    setLoaded(true);
    if (isPdf || shouldImportAnnotations) {
      setTimeout(() => {
        importAnnotation(res.data.record?.annotations);
      }, 3000);
    }
    else {
      if(!res.data.record?.is_document_loaded_in_etherPad) {
        documentService.updateDocument({
          id,
          is_document_loaded_in_etherPad: true
        });

        res.data.record.is_document_loaded_in_etherPad = true;
        setRecord(res.data.record);
      }
    }
  }

  useEffect(() => {
    getRecordById();
  }, [])

  // Export annotation as object.
  function exportAnnotation(){
    var viewer = document.getElementById('container').ej2_instances[0];
    viewer.exportAnnotationsAsObject().then(function(value) {
      if(!value) return;
      setExportObject(value);
      documentService.updateDocument({
        id,
        annotations: value
      });
    });
  }

  const isPdfUrl = (url) => {
    // Get the file extension from the URL
    const extension = url.split('.').pop().toLowerCase();
    // Check if the file extension is 'pdf'
    if(extension === 'pdf')
    {
      saveAnnotations = true;
      setIsPdf(true);
      return true;
    }
    else {
      saveAnnotations = false;
      return false;
    }
  }


  const constructIframeUrl = () => {
    if (!record) return '';

    // Construct the URL for the iframe
    const baseUrl = process.env.REACT_APP_PAD_URL + '/p/';
    const userName = `?userName=${encodeURIComponent(user && user.name ? user.name : 'Guest')}`;
    const queryParams = `&filePath=${encodeURIComponent(record.file)}&fileName=${encodeURIComponent(record.name)}`;

    return record.is_document_loaded_in_etherPad ? baseUrl + id + userName : baseUrl + id + userName + queryParams;
  };


  // Import annotation that are exported as object.
  function importAnnotation(annotations) {
    if(!annotations) return;
   var viewer = document.getElementById('container').ej2_instances[0];
   viewer.importAnnotation(JSON.parse(annotations));
  }

  useEffect(() => {
    let unlisten = () => {
      console.log('not initiallized yet');
    };

    // Add event listener for before route change
    unlisten = history.listen(handleRouteChange);

    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove event listener on cleanup
    return () => {
      unlisten();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [history]);

  // Function to handle route change
  function handleRouteChange() {
    // Call exportAnnotation function before leaving the page
    if(saveAnnotations) exportAnnotation();
  }

  // Function to handle beforeunload event
  function handleBeforeUnload(event) {
    // Cancel the event
    event.preventDefault();
    // Prompt the user with a confirmation message
    event.returnValue = '';
    // Call exportAnnotation function before leaving the page
    if(saveAnnotations) exportAnnotation();
  }

  function goBack(){
    if(isPdf)
    {
      handleRouteChange();
    }
    history.push('/documents/list');
  }

  function save()
  {
    if(isPdf)
    {
      handleRouteChange();
    }
    toast.success("Document Saved Successfully");
  }

  return (
    <div>
      <div className='control-section'>
      {loaded && record && (
        <>
          <div className="sidebar me-3 mb-3">
            <div style={{fontSize:18, fontWeight: "bold", margin: "20px 0"}}>
              <p className="mb-3"><b>Document Id : </b>{record.id }</p>
              <p className="mb-3"><b>Name : </b>{record.name}</p>
            </div>
            <div className="d-flex align-items-center">
              <button className="btn btn-success me-2" onClick={goBack}>Back</button>
              <button className="btn btn-primary" onClick={save}>Save</button>
            </div>
          </div>
          <div className="viewer-container" style={{ flex: 1 }}>
            {isPdf ? (
              <PdfViewerComponent
                id="container"
                ref={pdfViewerRef}
                documentPath={record.file}
                resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
                style={{ height: '740px' }}
                annotationSettings={{
                  author: user && user.name ? user.name : 'Guest'
                }}
                toolbarSettings={{ 
                  toolbarItems: ['CommentTool', 'AnnotationEditTool']
                }}
              >
                <Inject services={[Toolbar, Annotation]} />
              </PdfViewerComponent>
            ) : (
              <iframe
                style={{ width: '100%', height: '88vh' }}
                title="Document Preview"
                src={constructIframeUrl()}
                frameBorder="0"
              />
            )}
          </div>
        </>
      )}
      </div>
    </div>
  );
  
}

export default Viewer;