import axiosInstance from './axiosInstance.service';

const EntityService = {
  getEntityById: async (id) => {
    try {
      const res = await axiosInstance.get(`/classify/get_entity_by_id/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  updateEntity: async (data, id) => {
    try {
      const res = await axiosInstance.put(`/classify/update-entity/${id}`, data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  createEntity: async (data) => {
    try {
      const res = await axiosInstance.post(`/classify/create-entity`, data);
      return res;
    } catch (error) {
      throw error;
    }
  },
  deleteEntity: async (id) => {
    try {
      const res = await axiosInstance.delete(`/classify/delete-entity/${id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getEntities: async (params) => {
    try {
      const res = await axiosInstance.get(`/classify/get-entities`, { params });
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default EntityService;
