import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import AuthService from "@/service/auth.service";
import StorageService from "@/service/storage.service";
import NewRegisterForm from "@/shared/components/account/login_register/NewRegisterForm";
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from "@/shared/components/account/AccountElements";

const NewRegister = ({ history }) => {
  const [error, setError] = useState("");

  const registerFireBase = async (user) => {
    try {
      const response = await AuthService.signup({
        email: user.email,
        password: user.password,
        password2: user.password,
        name: user.name,
        tc: true,
      })
      if (response.status === 201 || response.status === 200) {
        if (response?.data?.token?.access) {
          toast.success(response?.data?.message);
          StorageService.userLogin(response?.data?.token?.access, response?.data?.data)
          history.push('/documents/list');
        }
      } else {
        toast.error(
          "Invalid email or password",
          { duration: 3000 }
        );
      }
    } catch (error) {
      if(error?.response?.data?.errors?.email) {
        setError(error?.response?.data?.errors?.email[0]);
      } else {
        toast.error(
          "Invalid email or password",
          { duration: 3000 }
        );
      }
    }
  };
  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>
              Welcome to
              <AccountLogo>
              {` Document`}
                <AccountLogoAccent> AI</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            <h4 className="subhead">Create an account</h4>
          </AccountHead>
          <NewRegisterForm onSubmit={registerFireBase} errorMessage={error} />
          <AccountHaveAccount>
            <p>
              Already have an account? <NavLink to="/login">Login</NavLink>
            </p>
          </AccountHaveAccount>
        </AccountCard>
      </AccountContent>
      <Toaster />
    </AccountWrap>
  );
};

NewRegister.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(NewRegister);
